<template>
  <master-detail
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    :customResource="customResource"
    descriptionProperty="id"
    :hasNewButton="false"
    :opts="opts"
  ></master-detail>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    'master-detail': () => import('@/components/master-detail.vue'),
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols: function () {
      return [
        {
          key: "funcionarioId",
          name: "ID",
          hideInform: true,
        },
        {
          key: "matricula",
          name: "Matrícula",
        },
        {
          key: "nome",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
        },
        {
          key: "grupoId",
          name: "Grupo de Encargo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "grupoEncargos", key: "id", name: "grupo" },
        },
        {
          key: "competenciaDe",
          name: "Competência de",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "competenciaAte",
          name: "Competência até",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/rh/grupoEncargo/cliente/${this.clientId}`);
      return {
        ...resource,
        get: (...args) => {
          return resource.get(...args).then((response) => {
            this.getEncargos();
            return response.map((grupo) => ({
              ...grupo,
              competenciaAte: grupo.competenciaAte == 0 ? 'Fim indefinido' : this.$options.filters.toMonth(grupo.competenciaAte),
              competenciaDe: grupo.competenciaDe == 0 ? 'Inicio indefinido' : this.$options.filters.toMonth(grupo.competenciaDe),
            }));
          });
        },
      };
    },
  },
  data: function () {
    return {
      opts: {
        grupoEncargos: [],
      },
    };
  },
  methods: {
    getEncargos: function () {
      this.apiResource(`/v1/rh/clientes/${this.clientId}/grupoEncargos`)
        .get()
        .then((response) => {
          this.opts.grupoEncargos = response;
        });
    },
  },
};
</script>
